import { optional, withDependencies } from '@wix/thunderbolt-ioc'
import { IAppDidMountHandler, IStructureAPI, StructureAPI } from '@wix/thunderbolt-symbols'
import { ITpaHandlersManager, TpaIncomingMessage } from './types'
import { IPageProvider, PageProviderSymbol } from 'feature-pages'
import { TpaHandlersManagerSymbol } from './symbols'
import { WindowMessageRegistrarSymbol, IWindowMessageRegistrar } from 'feature-window-message-registrar'
import { runtimeTpaCompIdBuilder } from '@wix/thunderbolt-commons'
import { TbDebugSymbol, DebugApis } from 'feature-debug'

const parseMessage = (evt: any) => {
	if (evt.data) {
		try {
			return JSON.parse(evt.data)
		} catch (e) {}
	}
	return {}
}

const isTpaMessage = (msg: TpaIncomingMessage<any>) => msg && ['TPA', 'TPA2'].includes(msg.intent)

const editorOnlyHandlers = ['getWixUpgradeUrl', 'stylesReady', 'getViewModeInternal', 'setHelpArticle']

export const TpaHandlersDistributor = withDependencies(
	[WindowMessageRegistrarSymbol, PageProviderSymbol, StructureAPI, optional(TbDebugSymbol)],
	(
		windowMessageRegistrar: IWindowMessageRegistrar,
		pageProvider: IPageProvider,
		structure: IStructureAPI,
		debugApi?: DebugApis
	): IAppDidMountHandler => {
		const getHandlersManagerForPage = async (pageId: string): Promise<ITpaHandlersManager> => {
			const pageRef = await pageProvider(pageId)
			return pageRef.getAllImplementersOnPageOf<ITpaHandlersManager>(TpaHandlersManagerSymbol)[0]
		}

		const getMessageSourceContainerId = ({ compId }: TpaIncomingMessage<any>): string | null => {
			if (!compId) {
				return null
			}
			if (compId.startsWith('tpaWorker_')) {
				return 'masterPage'
			}

			// treat runtime TPAs as if their origin component has sent the event
			const originCompId = runtimeTpaCompIdBuilder.getOriginCompId(compId)
			return structure.getContextIdOfCompId(originCompId)
		}

		return {
			appDidMount() {
				windowMessageRegistrar.addWindowMessageHandler({
					canHandleEvent(event: MessageEventInit) {
						return !!(event.source && isTpaMessage(parseMessage(event)))
					},
					async handleEvent(event: MessageEventInit) {
						const msg = parseMessage(event)
						const { type, callId, compId } = msg

						if (editorOnlyHandlers.includes(type)) {
							return
						}

						const contextId = getMessageSourceContainerId(msg)
						if (debugApi) {
							debugApi.tpa.addMessage({ msg, compId, contextId })
						}
						if (!contextId) {
							console.error('TPA handler message caller does not belong to any page', {
								type,
								callId,
								compId,
							})
							return
						}

						const pageHandlersManager = await getHandlersManagerForPage(contextId)

						pageHandlersManager.handleMessage(event.source as any, msg).catch((e) => {
							console.error('TpaHandlerError', type, contextId, compId, e)
						})
					},
				})
			},
		}
	}
)
